export const myLocalStorageSet = (key, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value)
  }
}

export const myLocalStorageGet = (key) => {
  if (typeof window !== "undefined") {
    return localStorage.getItem(key)
  }
}
