import React, { useContext, useState, useEffect, createContext } from "react"
import { myLocalStorageGet, myLocalStorageSet } from "./myState"

const defaultvalue = {
  honeInVisible: true,
  specificBanks: false,
  rewards: false,
  balanceTransfers: false,
  view: "grid",
  favouriteCards: false,
  favourite: false,
  free: false,
  airpoints: false,
  cashback: false,
  flybuys: false,
  sort: "purchasesrate",
  westpac: false,
  kiwibank: false,
  asb: false,
  anz: false,
  sbs: false,
  bnz: false,
  flightcenter: false,
  warehouse: false,
  gem: false,
  coop: false,
  showingOnlyLowFeeCardsPage: false,
}

const FilterContext = createContext(defaultvalue)
const FilterUpdateContext = createContext()

export function useFilter() {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error("Filter Context retrieval failed")
  }

  return context
}

export function useFilterUpdate() {
  const context = useContext(FilterUpdateContext)
  if (context === undefined) {
    throw new Error("Filter Update Context retrieval failed")
  }

  return context
}

export function FilterProvider({ children }) {
  const [honeInVisible, setHoneInVisible] = useState(() => {
    const value = myLocalStorageGet("honeInVisible")
    if (value === null) {
      myLocalStorageSet("honeInVisible", true)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [view, setView] = useState("grid")

  const [rewards, setRewards] = useState(() => {
    const value = myLocalStorageGet("rewards")
    if (value === null) {
      myLocalStorageSet("rewards", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [specificBanks, setSpecificBanks] = useState(() => {
    const value = myLocalStorageGet("specificBanks")
    if (value === null) {
      myLocalStorageSet("specificfBanks", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [balanceTransfers, setBalanceTransfers] = useState(() => {
    const value = myLocalStorageGet("balanceTransfers")
    if (value === null) {
      myLocalStorageSet("balanceTransfers", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [favouriteCards, setFavouriteCards] = React.useState(
    myLocalStorageGet("FavouriteCards")?.split(",") ?? []
  )

  const [favourite, setFavourite] = React.useState(() => {
    const value = myLocalStorageGet("FavouriteFilter")
    if (value === null) {
      myLocalStorageSet("FavouriteFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [sort, setSort] = React.useState(
    myLocalStorageGet("sort") || "purchasesrate"
  )

  const [free, setFree] = React.useState(() => {
    const value = myLocalStorageGet("FreeFilter")
    if (value === null) {
      myLocalStorageSet("FreeFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  ///////////////// Banks
  const [kiwibank, setKiwibank] = React.useState(() => {
    const value = myLocalStorageGet("kiwibankFilter")
    if (value === null) {
      myLocalStorageSet("kiwibankFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [bnz, setBnz] = React.useState(() => {
    const value = myLocalStorageGet("bnzFilter")
    if (value === null) {
      myLocalStorageSet("bnzFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })
  const [westpac, setWestpac] = React.useState(() => {
    const value = myLocalStorageGet("westpacFilter")
    if (value === null) {
      myLocalStorageSet("westpacFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })
  const [anz, setAnz] = React.useState(() => {
    const value = myLocalStorageGet("anzFilter")
    if (value === null) {
      myLocalStorageSet("anzFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })
  const [asb, setAsb] = React.useState(() => {
    const value = myLocalStorageGet("asbFilter")
    if (value === null) {
      myLocalStorageSet("asbFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })
  const [coop, setCoop] = React.useState(() => {
    const value = myLocalStorageGet("coopFilter")
    if (value === null) {
      myLocalStorageSet("coopFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })
  const [sbs, setSbs] = React.useState(() => {
    const value = myLocalStorageGet("sbsFilter")
    if (value === null) {
      myLocalStorageSet("sbsFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [flightcenter, setFlightcenter] = React.useState(() => {
    const value = myLocalStorageGet("flightcenterFilter")
    if (value === null) {
      myLocalStorageSet("flightcenterFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [warehouse, setWarehouse] = React.useState(() => {
    const value = myLocalStorageGet("warehouseFilter")
    if (value === null) {
      myLocalStorageSet("warehouseFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [gem, setGem] = React.useState(() => {
    const value = myLocalStorageGet("gemFilter")
    if (value === null) {
      myLocalStorageSet("gemFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  /////////////

  const [cashback, setCashback] = React.useState(() => {
    const value = myLocalStorageGet("CashbackFilter")
    if (value === null) {
      myLocalStorageSet("CashbackFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [flybuys, setFlybuys] = React.useState(() => {
    const value = myLocalStorageGet("FlybuysFilter")
    if (value === null) {
      myLocalStorageSet("FlybuysFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [benefits, setBenefits] = React.useState(() => [])

  const [airpoints, setAirpoints] = useState(() => {
    const value = myLocalStorageGet("AirpointsFilter")
    if (value === null) {
      myLocalStorageSet("AirpointsFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [freeBalanceTransfer, setFreeBalanceTransfer] = useState(() => {
    const value = myLocalStorageGet("FreeBalanceTransferFilter")
    if (value === null) {
      myLocalStorageSet("FreeBalanceTransferFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [twoYearBalanceTransfer, setTwoYearBalanceTransfer] = useState(() => {
    const value = myLocalStorageGet("TwoYearBalanceTransferFilter")
    if (value === null) {
      myLocalStorageSet("TwoYearBalanceTransferFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [oneYearBalanceTransfer, setOneYearBalanceTransfer] = useState(() => {
    const value = myLocalStorageGet("OneYearBalanceTransferFilter")
    if (value === null) {
      myLocalStorageSet("OneYearBalanceTransferFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [lifeBalanceTransfer, setLifeBalanceTransfer] = useState(() => {
    const value = myLocalStorageGet("LifeBalanceTransferFilter")
    if (value === null) {
      myLocalStorageSet("LifeBalanceTransferFilter", false)
      return false
    } else {
      return value === "true" ? true : false
    }
  })

  const [sixMonthsBalanceTransfer, setSixMonthsBalanceTransfer] = useState(
    () => {
      const value = myLocalStorageGet("SixMonthsBalanceTransferFilter")
      if (value === null) {
        myLocalStorageSet("SixMonthsBalanceTransferFilter", false)
        return false
      } else {
        return value === "true" ? true : false
      }
    }
  )

  const [showingOnlyLowFeeCardsPage, setShowingOnlyLowFeeCardsPage] = useState(
    () => {
      const value = myLocalStorageGet("ShowingOnlyLowFeeCardsPageFilter")
      if (value === null) {
        myLocalStorageSet("ShowingOnlyLowFeeCardsPageFilter", false)
        return false
      } else {
        return value === "true" ? true : false
      }
    }
  )

  let Filter = {
    lifeBalanceTransfer: lifeBalanceTransfer,
    sixMonthsBalanceTransfer: sixMonthsBalanceTransfer,
    oneYearBalanceTransfer: oneYearBalanceTransfer,
    twoYearBalanceTransfer: twoYearBalanceTransfer,
    freeBalanceTransfer: freeBalanceTransfer,
    honeInVisible: honeInVisible,
    specificBanks: specificBanks,
    rewards: rewards,
    balanceTransfers: balanceTransfers,
    view: view,
    favouriteCards: favouriteCards,
    favourite: favourite,
    free: free,
    airpoints: airpoints,
    cashback: cashback,
    flybuys: flybuys,
    sort: sort,
    westpac: westpac,
    kiwibank: kiwibank,
    asb: asb,
    anz: anz,
    sbs: sbs,
    bnz: bnz,
    flightcenter: flightcenter,
    warehouse: warehouse,
    gem: gem,
    coop: coop,
    showingOnlyLowFeeCardsPage: showingOnlyLowFeeCardsPage,
  }

  const toggleAirpoints = (val, data) => {
    let current

    switch (val) {
      case "Reset":
        Reset()
        break
      case "lifeBalanceTransfer":
        current = lifeBalanceTransfer
        setLifeBalanceTransfer(!current)
        myLocalStorageSet("LifeBalanceTransferFilter", !current)
        break
      case "twoYearBalanceTransfer":
        current = twoYearBalanceTransfer
        setTwoYearBalanceTransfer(!current)
        myLocalStorageSet("TwoYearBalanceTransferFilter", !current)
        break
      case "oneYearBalanceTransfer":
        current = oneYearBalanceTransfer
        setOneYearBalanceTransfer(!current)
        myLocalStorageSet("OneYearBalanceTransferFilter", !current)
        break
      case "sixMonthsBalanceTransfer":
        current = sixMonthsBalanceTransfer
        setSixMonthsBalanceTransfer(!current)
        myLocalStorageSet("SixMonthsBalanceTransferFilter", !current)
        break
      case "freeBalanceTransfer":
        current = freeBalanceTransfer
        setFreeBalanceTransfer(!current)
        myLocalStorageSet("FreeBalanceTransferFilter", !current)
        break
      case "honeInVisible":
        current = honeInVisible
        setHoneInVisible(!current)
        myLocalStorageSet("honeInVisible", !current)
        break
      case "specificBanks":
        current = specificBanks
        setSpecificBanks(!current)
        myLocalStorageSet("specificBanks", !current)
        if (!current && honeInVisible) {
          setHoneInVisible(false)
          myLocalStorageSet("honeInVisible", false)
        } else {
          setHoneInVisible(true)
          myLocalStorageSet("honeInVisible", true)
        }
        break
      case "rewards":
        current = rewards
        setRewards(!current)
        myLocalStorageSet("rewards", !current)
        if (!current && honeInVisible) {
          setHoneInVisible(false)
          myLocalStorageSet("honeInVisible", false)
        } else {
          setHoneInVisible(true)
          myLocalStorageSet("honeInVisible", true)
        }
        break
      case "balanceTransfers":
        current = balanceTransfers
        setBalanceTransfers(!current)
        myLocalStorageSet("balanceTransfers", !current)
        if (!current && honeInVisible) {
          setHoneInVisible(false)
          myLocalStorageSet("honeInVisible", false)
        } else {
          setHoneInVisible(true)
          myLocalStorageSet("honeInVisible", true)
        }
        break
      case "view":
        current = view
        if (current == "list") {
          current = "grid"
        } else {
          current = "list"
        }
        setView(current)
        myLocalStorageSet("view", current)
        break
      case "favouriteCards":
        current = favouriteCards
        if (!current?.includes(data)) {
          if (current === null || current === "null") {
            setFavouriteCards([data])
            myLocalStorageSet("FavouriteCards", [data])
          } else {
            setFavouriteCards((current) => [...current, data])
            myLocalStorageSet("FavouriteCards", [...current, data])
          }
        } else {
          if (current) {
            current = current.filter((value) => value !== data)
            setFavouriteCards(current)
            myLocalStorageSet("FavouriteCards", current)
          }
        }
        break
      case "favourite":
        current = favourite
        setFavourite(!current)
        myLocalStorageSet("FavouriteFilter", !current)
        break
      case "free":
        current = free
        setFree(!current)
        myLocalStorageSet("FreeFilter", !current)
        break
      case "gem":
        current = gem
        setGem(!current)
        myLocalStorageSet("gemFilter", !current)
        break
      case "warehouse":
        current = warehouse
        setWarehouse(!current)
        myLocalStorageSet("warehouseFilter", !current)
        break
      case "flightcenter":
        current = flightcenter
        setFlightcenter(!current)
        myLocalStorageSet("flightcenterFilter", !current)
        break
      case "sbs":
        current = sbs
        setSbs(!current)
        myLocalStorageSet("sbsFilter", !current)
        break
      case "coop":
        current = coop
        setCoop(!current)
        myLocalStorageSet("coopFilter", !current)
        break
      case "asb":
        current = asb
        setAsb(!current)
        myLocalStorageSet("asbFilter", !current)
        break
      case "anz":
        current = anz
        setAnz(!current)
        myLocalStorageSet("anzFilter", !current)
        break
      case "bnz":
        current = bnz
        setBnz(!current)
        myLocalStorageSet("bnzFilter", !current)
        break
      case "kiwibank":
        current = kiwibank
        setKiwibank(!current)
        myLocalStorageSet("kiwibankFilter", !current)
        break
      case "westpac":
        current = westpac
        setWestpac(!current)
        myLocalStorageSet("westpacFilter", !current)
        break
      case "sort":
        setSort(data)
        myLocalStorageSet("sort", data)
        break
      case "airpoints":
        current = airpoints
        setAirpoints(!current)
        myLocalStorageSet("AirpointsFilter", !current)
        break
      case "flybuys":
        current = flybuys
        setFlybuys(!current)
        myLocalStorageSet("FlybuysFilter", !current)
        break
      case "cashback":
        current = cashback
        setCashback(!current)
        myLocalStorageSet("CashbackFilter", !current)
        break
      default:
        console.log("unknown toggle in useContext")
    }

    if (airpoints) {
      // make sure we have .airpoints in the array
      if (!benefits.includes(".airpoints")) {
        let newBenefits = benefits
        newBenefits.push(".airpoints")
        setBenefits(newBenefits)
        myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
      }
    } else {
      // make sure we don't have .airpoints in the array
      let newBenefits = benefits
      newBenefits = newBenefits.filter((i) => i !== ".airpoints")
      setBenefits(newBenefits)
      myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
    }

    if (flybuys) {
      // make sure we have .flybuys in the array
      if (!benefits.includes(".flybuys")) {
        let newBenefits = benefits
        newBenefits.push(".flybuys")
        setBenefits(newBenefits)
        myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
      }
    } else {
      // make sure we don't have .flybuys in the array
      let newBenefits = benefits
      newBenefits = newBenefits.filter((i) => i !== ".flybuys")
      setBenefits(newBenefits)
      myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
    }

    if (cashback) {
      // make sure we have .cashback in the array
      if (!benefits.includes(".cashBack")) {
        let newBenefits = benefits
        newBenefits.push(".cashBack")
        setBenefits(newBenefits)
        myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
      }
    } else {
      // make sure we don't have .cashback in the array
      let newBenefits = benefits
      newBenefits = newBenefits.filter((i) => i !== ".cashBack")
      setBenefits(newBenefits)
      myLocalStorageSet("BenefitsFilter", JSON.stringify(newBenefits))
    }
  }

  const Reset = () => {
    if (!Filter?.honeInVisible) toggleAirpoints("honeInVisible")

    if (Filter?.specificBanks) toggleAirpoints("specificBanks")

    if (Filter?.balanceTransfers) toggleAirpoints("balanceTransfers")

    if (Filter?.twoYearBalanceTransfer)
      toggleAirpoints("twoYearBalanceTransfer")

    if (Filter?.oneYearBalanceTransfer)
      toggleAirpoints("oneYearBalanceTransfer")

    if (Filter?.sixMonthsBalanceTransfer)
      toggleAirpoints("sixMonthsBalanceTransfer")

    if (Filter?.freeBalanceTransfer) toggleAirpoints("FreeBalanceTransfer")

    if (Filter?.rewards) toggleAirpoints("rewards")

    if (Filter?.free) toggleAirpoints("free")

    if (Filter?.cashback) toggleAirpoints("cashback")

    if (Filter?.airpoints) toggleAirpoints("airpoints")

    if (Filter?.flybuys) toggleAirpoints("flybuys")

    if (Filter?.favourite) toggleAirpoints("favourite")

    if (Filter?.anz) toggleAirpoints("anz")

    if (Filter?.asb) toggleAirpoints("asb")

    if (Filter?.bnz) toggleAirpoints("bnz")

    if (Filter?.coop) toggleAirpoints("coop")

    if (Filter?.flightcenter) toggleAirpoints("flightcenter")

    if (Filter?.kiwibank) toggleAirpoints("kiwibank")

    if (Filter?.gem) toggleAirpoints("gem")

    if (Filter?.sbs) toggleAirpoints("sbs")

    if (Filter?.westpac) toggleAirpoints("westpac")

    if (Filter?.warehouse) toggleAirpoints("warehouse")
  }

  return (
    <FilterContext.Provider value={Filter}>
      <FilterUpdateContext.Provider value={toggleAirpoints}>
        {children}
      </FilterUpdateContext.Provider>
    </FilterContext.Provider>
  )
}
